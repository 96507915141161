import React from 'react'
import './Gem.css'
import Heart from './heart.png'
import NoFight from './noFight.png'
import String from '../string/String.js'
import GemGreen from './gem-green.png'
import GemYellow from './gem-yellow.png'
import GemRed from './gem-red.png'
import GemBlue from './gem-blue.png'
import GemGray from './gem-gray.png'
import GemPurple from './gem-purple.png'

export default function Gem(props) {

	const colors = {
		green: GemGreen,
		yellow: GemYellow,
		red: GemRed,
		blue: GemBlue,
		gray: GemGray,
		purple: GemPurple
	}

	let action = props.action ? <span className={`icon icon-${props.action}`}></span> : null
	let heart = props.heart ? <img src={Heart} className="gem-heart" alt="" /> : null
	const noFight = props.noFight ? <img src={NoFight} className="gem-noFight" alt="" /> : null

	return (
		<div className="gem icon-white" style={{'backgroundImage':  `url(${colors[props.color]?colors[props.color]:colors.gray})`}}>
			{action}
			{props.command && <String className="gem-command">{props.command}</String>}
			{heart}
			{noFight}
		</div>
	)
}